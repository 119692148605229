/**
 * Authentication context
 *
 * Authenticates/deauthenticates a user and returns the current authentication state
 */
import {track, identify, Identify} from "@amplitude/analytics-browser";
import {useLazyQuery} from "@apollo/client";
import fetch from "cross-fetch";
import dayjs from "dayjs";
import React, {useState} from "react";
import {useCookies} from "react-cookie";

import config from "../../config";
import {TOKEN_COOKIE_NAME} from "../../constants";
import {queryWithNextContext} from "../../graphql/client";
import * as queries from "../../graphql/queries";
import type {User, Permissions} from "../../graphql/types";
import {getLogger} from "../../lib/logging";

const log = getLogger(__filename);

type StatusType = "SIGNED_IN" | "SIGNED_OUT" | "ERROR";

export interface AuthenticationState {
  status: StatusType;
  user: User | null;
}

export interface AuthContextState {
  authentication: AuthenticationState;

  /**
   * Sign in user
   *
   * @param email
   * @param password
   * @return Next authentication state
   */
  login(email: string, password: string): Promise<AuthenticationState>;

  /**
   * Sign out current user
   *
   * @return Next authentication state
   */
  logout(): Promise<AuthenticationState>;

  /**
   * Refresh the current auth state
   *
   * Primarily used to refresh current user and settings, and should only
   * be called when already signed in.
   */
  refresh(): Promise<AuthenticationState>;

  /**
   * Check if current user has an assigned permission
   * @param permission Permission to check
   * @return Whether or not the current user has the assigned permission
   */
  hasPermission(permission: Permissions): boolean;
}

const SIGNED_OUT_STATE: AuthenticationState = {
  status: "SIGNED_OUT",
  user: null,
};

const AuthContext = React.createContext<AuthContextState>({
  authentication: SIGNED_OUT_STATE,
  login: async (
    _email: string,
    _password: string
  ): Promise<AuthenticationState> => SIGNED_OUT_STATE,
  logout: async (): Promise<AuthenticationState> => SIGNED_OUT_STATE,
  refresh: async () => SIGNED_OUT_STATE,
  hasPermission: (_permission: Permissions) => false,
});

export interface AuthProviderProps {
  authentication?: AuthenticationState;
  client: any;
  children: React.ReactNode;
}

/**
 * Get the current authentication state
 *
 * @param client GraphQL client
 * @param context
 * @return Authentication state
 */
export const getAuthenticationState = async (
  client: any,
  context: any
): Promise<AuthenticationState> => {
  const {data: {users: {currentUser = undefined} = {}} = {}} =
    await queryWithNextContext(
      context,
      {
        query: queries.GET_CURRENT_USER,
      },
      client
    );

  if (currentUser) {
    return {
      status: "SIGNED_IN",
      user: currentUser,
    };
  } else {
    return SIGNED_OUT_STATE;
  }
};

export const AuthProvider = ({
  client,
  authentication: initialAuthentication = SIGNED_OUT_STATE,
  children,
}: AuthProviderProps) => {
  const [_cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);
  const [authentication, setAuthentication] = useState<AuthenticationState>(
    initialAuthentication
  );
  const [getCurrentUser, _getCurrentUserResult] = useLazyQuery(
    queries.GET_CURRENT_USER
  );

  const failMessage = "An unexpected error occurred while signing in";

  const login = async (
    email: string,
    password: string
  ): Promise<AuthenticationState> => {
    let data;

    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    const res = await fetch(`${config.apiUrl}/token`, {
      method: "POST",
      body: formData,
    });

    const success = res.status === 200;
    track("Signed In", {
      success,
    });

    if (success) {
      data = await res.json();
    } else {
      let error;
      try {
        error = await res.json();
      } catch {
        throw new Error(failMessage);
      }

      throw new Error(error.detail || failMessage);
    }

    if (data?.access_token) {
      const expires = dayjs().add(1, "years").toDate();
      setCookie(TOKEN_COOKIE_NAME, data.access_token, {expires, path: "/"});

      // Identify user in analytics
      const identity = new Identify();
      identity.set("email", email);

      // User IDs must be 5 chars min length in Amplitude
      const amplitudeUserId = ("" + data.user_id).padStart(5, "0");
      identify(identity, {
        user_id: amplitudeUserId,
        app_version: config.version,
      });

      log.info("Clearing store...");
      await client.clearStore();

      log.info("Getting current user...");
      const {
        error: currentUserError,
        data: {users: {currentUser = undefined} = {}},
      } = await getCurrentUser();

      let authState: AuthenticationState;
      if (currentUserError) {
        log.info(`Unable to get current user: ${currentUserError}`);
        authState = {
          status: "ERROR",
          user: null,
        };
      } else {
        log.info("Sign in successful");
        authState = {
          status: "SIGNED_IN",
          user: currentUser,
        };
      }

      setAuthentication(authState);

      // log.info("Resetting store...");
      // await client.resetStore();

      return authState;
    } else {
      throw new Error(failMessage);
    }
  };

  const logout = async (): Promise<AuthenticationState> => {
    track("Signed Out");

    removeCookie(TOKEN_COOKIE_NAME);

    log.info("Clearing store...");
    await client.clearStore();

    setAuthentication(SIGNED_OUT_STATE);

    // log.info("Resetting store...");
    // await client.resetStore();

    log.info("Sign out successful");
    return SIGNED_OUT_STATE;
  };

  const refresh = async (): Promise<AuthenticationState> => {
    // Only care about refreshing if already signed in
    if (authentication.status !== "SIGNED_IN") {
      return authentication;
    }

    const {
      error: currentUserError,
      data: {users: {currentUser = undefined} = {}},
    } = await getCurrentUser();

    let authState: AuthenticationState;

    if (currentUserError) {
      log.info(`Unable to get current user: ${currentUserError}`);
      authState = {
        status: "ERROR",
        user: null,
      };
    } else {
      authState = {
        status: "SIGNED_IN",
        user: currentUser,
      };
    }

    setAuthentication(authState);

    return authState;
  };

  const hasPermission = (permission: Permissions): boolean => {
    return (authentication.user?.permissions || []).includes(permission);
  };

  return (
    <AuthContext.Provider
      value={{
        authentication,
        login,
        logout,
        refresh,
        hasPermission,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextState => React.useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;

import type {UserSettings} from "./types";
import type {User} from "../graphql/types";

export const DEFAULT_SETTINGS: UserSettings = {
  legacy_search: false,
  disable_email_alerts: false,
  unsubscribe: false,
  disable_tutorial: false,
  completed_tutorials: [],
};

/**
 * Get settings for a User
 *
 * @param user User to get settings for
 * @return Settings for user
 */
export function getUserSettings(user?: User | null): UserSettings {
  if (!user || !user.settingsJson) {
    return DEFAULT_SETTINGS;
  }

  const settings = JSON.parse(user.settingsJson) as {[key: string]: any};
  return {...DEFAULT_SETTINGS, ...settings};
}

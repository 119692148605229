import pino, {Logger} from "pino";

import config from "../config";

const packageName = process.env.PACKAGE_NAME;

let serverOpts: any = {};
if (!config.env || config.env === "development") {
  serverOpts = {
    // Pretty print and colorize server-side logs
    transport: {
      target: "pino-pretty",
      options: {
        colorize: true,
      },
    },
  };
}

const rootLogger: Logger = pino({
  name: packageName,
  level: "debug", // One of: fatal, error, warn, info, debug, trace, silent

  // Client-side logs
  browser: {
    disabled: false,
    // asObject: true,
    serialize: true,
    // transmit: {
    //   level: "debug",
    //   send(level, logEvent: pino.LogEvent) {
    //     // TODO: Ship logs
    //     // if (config.sentry.dsn) {
    //     //   Sentry.captureMessage(logEvent);
    //     // }
    //     console.log("Ship log", logEvent);
    //   },
    // },
  },

  ...serverOpts,
});

/**
 *
 * @param modulePath
 * @param className
 */
export function getLogger(modulePath: string, className?: string): Logger {
  const match = modulePath.match(/\/(.+?)\.([tj]sx?)$/);
  if (!match) {
    return rootLogger;
  }

  const [_prefix, partialPath, _ext] = match;

  let moduleName = packageName;

  if (partialPath) {
    moduleName = `${moduleName}.${partialPath.replace(/\\|\//, ".")}`;
  }

  if (className) {
    moduleName = `${moduleName}.${className}`;
  }

  return rootLogger.child({module: moduleName});
}

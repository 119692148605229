/**
 * Position context
 *
 * Provides user's current geolocation
 */
import {noop} from "lodash";
import React, {useState, createContext} from "react";

import {getLogger} from "../../lib/logging";

const log = getLogger(__filename);

const positionOptions: PositionOptions = {
  timeout: 60 * 1000, // Timeout before failing position lookup (in ms)
  maximumAge: 100, // Max age of cached position (in ms)
};

interface UsePosition {
  coordinates: GeolocationCoordinates | null;
  updating: boolean;
  watching: boolean;
  watchPosition(enabled: boolean): void;
  updatePosition(): void;
}

type ControlState = {
  updating: boolean;
  watchId: number | null;
  wakeLock: WakeLockSentinel | null;
};

export function usePosition(): UsePosition {
  const [state, setState] = useState<ControlState>({
    updating: false,
    watchId: null,
    wakeLock: null,
  });

  const [lastPosition, setLastPosition] = useState<GeolocationPosition | null>(
    null
  );

  const wakeLockSupported =
    typeof navigator !== "undefined" && "wakeLock" in navigator;

  const watchPosition = (enabled: boolean) => {
    if (!navigator?.geolocation) {
      // Geolocation API not supported
      return;
    }

    if (enabled) {
      if (state.watchId) {
        // Already watching
        return;
      }

      // Start watching
      log.debug("Watching position...");
      const watchId = navigator.geolocation.watchPosition(
        position => {
          // Position changed
          if (lastPosition && position.timestamp < lastPosition.timestamp) {
            // Outdated
            return;
          }

          log.debug(
            `Watched position updated: ${position.coords.latitude},${position.coords.longitude}`
          );
          setLastPosition(position);
        },
        error => {
          log.error(
            `Error ${error.code} updating watched position: ${error.message}`
          );
        },
        {
          ...positionOptions,
          enableHighAccuracy: true,
        }
      );

      // Enable wake lock to keep the device alive while the position is being live tracked,
      // otherwise the display will shut off while the map is being viewed
      if (wakeLockSupported) {
        navigator?.wakeLock?.request("screen").then(wakeLock => {
          log.debug("Wake lock acquired");
          setState({...state, watchId, wakeLock});
        });
      } else {
        setState({...state, watchId});
      }
    } else {
      stopWatching();
    }
  };

  const stopWatching = () => {
    // Stop watching
    if (state.watchId) {
      navigator?.geolocation?.clearWatch(state.watchId);
      log.debug("Stopped watching position");
    }

    // Disable wake lock
    if (wakeLockSupported && state.wakeLock) {
      state.wakeLock.release().then(() => {
        log.debug("Wake lock released");
      });
    }

    setState({...state, wakeLock: null, watchId: null});
  };

  const updatePosition = () => {
    if (!navigator?.geolocation) {
      // Geolocation API not supported
      return;
    }

    if (state.updating) {
      // Already updating
      return;
    }

    log.debug("Updating position...");
    setState({...state, updating: true});
    navigator.geolocation?.getCurrentPosition(
      position => {
        setState({...state, updating: false});

        if (lastPosition && position.timestamp < lastPosition.timestamp) {
          // Outdated
          return;
        }

        setLastPosition(position);
        log.debug(
          `Position updated: ${position.coords.latitude},${position.coords.longitude}`
        );
      },
      error => {
        setState({...state, updating: false});

        log.error(
          `Error ${error.code} when updating watched position: ${error.message}`
        );
      },
      {
        ...positionOptions,
        enableHighAccuracy: true, // Really slows down position fixes
      }
    );
  };

  return {
    coordinates: lastPosition?.coords || null,
    updating: state.updating,
    watching: state.watchId !== null,
    watchPosition,
    updatePosition,
  };
}

let PositionContext: React.Context<UsePosition>;
const {Provider} = (PositionContext = createContext<UsePosition>({
  coordinates: null,
  updating: false,
  watching: false,
  watchPosition: noop,
  updatePosition: noop,
}));

export interface PositionProviderProps {
  children: React.ReactNode;
}

const PositionProvider = ({children}: PositionProviderProps) => {
  const providerValue = usePosition();

  return <Provider value={providerValue}>{children}</Provider>;
};

export {PositionContext, PositionProvider};

import {track} from "@amplitude/analytics-browser";
import {ContextModalProps, modals} from "@mantine/modals";
import {noop} from "lodash";
import React, {useState, useEffect} from "react";

import classes from "./CombinedSignIn.module.scss";
import SignInForm from "../../SignInForm";
import SignUpForm from "../../SignUpForm";

// Dialog mode
type Mode = "signIn" | "signUp";

export interface CombinedSignInProps {
  mode: Mode; // Dialog mode
  message?: React.ReactNode; // Message to display at top (e.g. "sign up to do some action")
  onComplete?(): void; // Callback to invoke when sign-in or sign-up is complete (put your authenticated actions here)
}

/**
 * Combined sign-in/sign-up dialog
 */
export default function CombinedSignIn({
  context,
  id,
  innerProps: props,
}: ContextModalProps<CombinedSignInProps>) {
  const [mode, setMode] = useState<Mode>(props.mode);
  const buttonSize = "sm";

  useEffect(() => {
    const title = modeTitle(mode);
    context.updateContextModal({
      modalId: id,
      title: <div className={classes.title}>{title}</div>,
    });
    track(`${title} Modal Viewed`);
  }, [mode]);

  let form = null;
  if (mode === "signIn") {
    form = (
      <SignInForm
        message={props.message}
        buttonSize={buttonSize}
        onComplete={() => {
          context.closeModal(id);
          if (props.onComplete) {
            props.onComplete();
          }
        }}
        onSignUpClick={() => setMode("signUp")}
        onNavigateAway={() => context.closeModal(id)}
      />
    );
  } else if (mode === "signUp") {
    form = (
      <SignUpForm
        message={props.message}
        buttonSize={buttonSize}
        onComplete={() => {
          context.closeModal(id);
        }}
        onSignInClick={() => setMode("signIn")}
        onNavigateAway={() => context.closeModal(id)}
      />
    );
  }

  return form;
}

/**
 * Show combined sign-in/sign-up modal
 *
 * @param innerProps Properties for modal
 */
export function show(innerProps: CombinedSignInProps) {
  const modalId = "combinedSignIn";
  modals.close(modalId); // Ensure duplicate isn't opened
  modals.openContextModal({
    modal: modalId,
    centered: true,
    withOverlay: true,
    keepMounted: false,
    closeOnClickOutside: false,
    size: "md",
    innerProps: {
      onComplete: noop,
      ...innerProps,
    },
  });
}

/**
 * Show sign-in modal
 *
 * @param message Message to display at top
 */
export function showSignIn(message?: string) {
  show({mode: "signIn", message});
}

/**
 * Show sign-up modal
 *
 * @param message Message to display at top
 */
export function showSignUp(message?: string) {
  show({mode: "signUp", message});
}

/**
 * @param mode Current mode
 * @return Dialog title for current mode
 */
function modeTitle(mode: Mode): string {
  if (mode === "signIn") {
    return "Sign in";
  } else if (mode === "signUp") {
    return "Sign up";
  } else {
    throw new Error(`Invalid mode: ${mode}`);
  }
}

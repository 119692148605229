// Note: Environment variables referenced here should be included in next.config.js
const config: any = {
  env: process.env.NODE_ENV,
  version: process.env.PACKAGE_VERSION,
  apiUrl: process.env.CAMPOBSERVER_API_URL,
  graphqlUrl: `${process.env.CAMPOBSERVER_API_URL}/graphql`,
  graphqlWsUrl: `${process.env.CAMPOBSERVER_API_WS_URL}/graphql`,
  amplitude: {
    apiKey: process.env.AMPLITUDE_API_KEY || "",
  },
  mapbox: {
    token: process.env.MAPBOX_TOKEN || "",
  },
  hcaptcha: {
    siteKey: process.env.HCAPTCHA_SITE_KEY || "",
  },
  edgeConfig: process.env.EDGE_CONFIG || "",
  disableMaintenanceMode:
    process.env.CAMPOBSERVER_DISABLE_MAINTENANCE_MODE == "true",
};

export default config;
